import styled from "@emotion/styled";
import { NextPage } from "next";
import { NextSeo } from "next-seo";
import React from "react";
import { useIntl } from "react-intl";
import Widget from "widgets/ConnectWallet/ConnectWallet";

const Wrapper = styled.main`
  width: 100vw;
  height: 100vh;
`;

const ConnectWallet: NextPage = () => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <NextSeo title={intl.formatMessage({ defaultMessage: "Connect wallet | OX.FUN" })} />
      <Wrapper>
        <Widget />
      </Wrapper>
    </React.Fragment>
  );
};

export default ConnectWallet;
