import styled from "@emotion/styled";
import { Loading, SystemLogo } from "@oxfun/uikit";
import dynamic from "next/dynamic";
import Link from "next/link";
import { memo } from "react";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-top: 40vh;

  & > a {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
  }
`;

const Loader = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
`;

const Container = dynamic(() => import("./Container"), {
  ssr: false,
  loading: () => (
    <Loader>
      <Loading loading css={{ width: "100vw", height: "100vh" }} />
    </Loader>
  )
});

const ConnectWallet = memo(() => {
  return (
    <Wrapper>
      <Link href={"/"}>
        <SystemLogo />
      </Link>
      <Container />
    </Wrapper>
  );
});

export default ConnectWallet;
